import { useCallback, useRef } from 'react';
import { testMediaConnectionBitrate, MediaConnectionBitrateTest, DiagnosticError } from '@twilio/rtc-diagnostics';

export type BitrateTestParams = {
  setBitrate?: (bitrate?: number) => void;
  setError?: (error?: DiagnosticError | any) => void;
  setReport?: (report?: MediaConnectionBitrateTest.Report) => void;
  onStart?: () => void;
  onFinish?: () => void;
  duration?: number;
};

export default function useBitrateTest({
  setBitrate,
  setError,
  onStart,
  onFinish,
  setReport,
  duration,
}: BitrateTestParams) {
  const bitrateTestRef = useRef<MediaConnectionBitrateTest>();
  const startBitrateTest = async () => {
    //Don't start a new bitrate test if one is already running:
    if (bitrateTestRef.current) {
      return;
    }

    typeof onStart === 'function' && onStart();

    try {
      const response = await fetch(
        process.env.REACT_APP_DIAGNOSTICS_TURN_CREDENTIALS_ENDPOINT || 'app/turn-credentials'
      );
      const json = await response.json();
      const bitrateTest = testMediaConnectionBitrate({ iceServers: json.iceServers });

      bitrateTestRef.current = bitrateTest;

      bitrateTest.on(MediaConnectionBitrateTest.Events.Bitrate, bitrate => {
        typeof setBitrate === 'function' && setBitrate(bitrate);
      });

      bitrateTest.on(MediaConnectionBitrateTest.Events.Error, error => {
        typeof setError === 'function' && setError(error);
        typeof onFinish === 'function' && onFinish();
        bitrateTestRef.current = undefined;
      });

      bitrateTest.on(MediaConnectionBitrateTest.Events.End, report => {
        typeof setReport === 'function' && setReport(report);
        typeof onFinish === 'function' && onFinish();
        bitrateTestRef.current = undefined;
      });

      setTimeout(() => {
        bitrateTest.stop();
      }, duration);
    } catch (error) {
      typeof setError === 'function' && setError(error);
      typeof onFinish === 'function' && onFinish();
    }
  };

  return { startBitrateTest } as const;
}
