import React, { useRef } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';

import Button from '@material-ui/core/Button';
import FileAttachmentIcon from '../../../icons/FileAttachmentIcon';

import { useAppState } from '../../../state/index';

export default function UploadButton() {
  const { tokenApi, idPatient } = useAppState();
  const endpointExame = process.env.REACT_APP_ENDPOINT_EXAMES;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files?.[0];
    if (file) {
      uploadFile(file);
    }
  };

  const uploadFile = (file: File) => {
    Swal.fire({
      icon: 'info',
      title: 'Atenção',
      text: 'Seu exame está sendo enviado...',
    });

    const dataAtual = new Date();
    const dia = dataAtual.getDate();
    const mes = dataAtual.getMonth();
    const ano = dataAtual.getFullYear();

    const formData = new FormData();
    formData.append('exame', file);
    formData.append('descricao', `${dia + mes + ano + '_' + file.name}`);
    formData.append('idPaciente', idPatient);

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: `Bearer ${tokenApi}`,
    };

    axios
      .post(endpointExame + '/' + idPatient + '/exames', formData, { headers })
      .then(response => {
        Swal.fire('Exame enviado!', 'Clique no botão abaixo para fechar!', 'success');
      })
      .catch(error => {
        Swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Não foi possível enviar seu exame!',
        });
      });
  };

  return (
    <div>
      <Button onClick={handleButtonClick} startIcon={<FileAttachmentIcon />}>
        Enviar exames
      </Button>
      <input type="file" style={{ display: 'none' }} onChange={handleFileChange} ref={fileInputRef} />
    </div>
  );
}
