import React, { useCallback, useRef } from 'react';
import axios from 'axios';
import { PreflightTest, PreflightTestReport, runPreflight } from 'twilio-video';

export type PreflightTestParams = {
  setPreflightProgress?: (progress?: string) => void;
  setReport?: (report?: PreflightTestReport) => void;
  setError?: (error?: any) => void;
  onFinish?: () => void;
  onStart?: () => void;
};

export default function usePreflightTest({
  onFinish,
  setError,
  setPreflightProgress,
  setReport,
  onStart,
}: PreflightTestParams) {
  const preflightTestRef = useRef<PreflightTest>();
  const startPreflightTest = useCallback(async () => {
    // Don't start a new preflight test if one is already running
    if (preflightTestRef.current) {
      return;
    }

    typeof onStart === 'function' && onStart();
    try {
      const response = await fetch(process.env.REACT_APP_DIAGNOSTICS_TOKEN_ENDPOINT || '');
      const json = await response.json();
      const preflightTest = runPreflight(json.token);

      preflightTestRef.current = preflightTest;

      preflightTest.on('progress', progress => {
        typeof setPreflightProgress === 'function' && setPreflightProgress(progress);
      });

      preflightTest.on('completed', report => {
        typeof setReport === 'function' && setReport(report);
        typeof onFinish === 'function' && onFinish();
        preflightTestRef.current = undefined;
      });

      preflightTest.on('failed', error => {
        typeof setError === 'function' && setError(error);
        typeof onFinish === 'function' && onFinish();
        preflightTestRef.current = undefined;
      });
    } catch (error) {
      typeof setError === 'function' && setError(error);
      typeof onFinish === 'function' && onFinish();
      preflightTestRef.current = undefined;
    }
  }, [onFinish, setError, setPreflightProgress, setReport, onStart]);

  return { startPreflightTest } as const;
}
