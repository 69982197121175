import React from 'react';
import Video from 'twilio-video';
import { Container, Link, Typography, Paper, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles({
  container: {
    marginTop: '2.5em',
  },
  paper: {
    padding: '1em',
  },
  heading: {
    marginBottom: '0.4em',
  },
});

export default function UnsupportedBrowserWarning(props: any) {
  const classes = useStyles();
  if (!Video.isSupported) {
    return (
      <Alert severity="error">
        Navegador não suportado. Utilize o <b>Google Chrome</b> ou o <b>Safari</b>
      </Alert>
    );
  }

  return <></>;
}
