import React from 'react';
import { makeStyles, createStyles } from '@material-ui/styles';
import { Theme, Button } from '@material-ui/core';
import useHelpContext from '../../../hooks/useHelpContext/useHelpContext';
import { HelpStepTag } from '../../HelpProvider';
import useBasePageStyles from '../useBasePageStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: 'center',
    },
    title: {
      marginTop: 0,
      fontSize: '1.5em',
    },
    subtitle: {
      margin: '1.5em 0',
    },
    ulButtons: {
      padding: 0,
      listStyleType: 'none',
      '& li': {
        marginBottom: theme.typography.fontSize,
      },
    },
  })
);

export default function MainPage() {
  const baseClasses = useBasePageStyles();
  const classes = useStyles();
  const context = useHelpContext();

  const handleClickButton = (nextPage: HelpStepTag) => {
    context.setState({
      ...context.state,
      historySteps: [...context.state.historySteps, nextPage],
    });
  };

  return (
    <div className={classes.container}>
      <h1 className={classes.title}>Precisa de ajuda?</h1>
      <p className={classes.subtitle}>Selecione qual problema está enfrentando</p>
      <ul className={classes.ulButtons}>
        <li>
          <Button
            variant="contained"
            className={baseClasses.button}
            color="primary"
            onClick={() => handleClickButton('falha-audio')}
          >
            Não estou ouvindo o profissional
          </Button>
        </li>
        <li>
          <Button
            variant="contained"
            className={baseClasses.button}
            color="primary"
            onClick={() => handleClickButton('falha-video')}
          >
            Não estou vendo o profissional
          </Button>
        </li>
        <li>
          <Button
            variant="contained"
            className={baseClasses.button}
            color="primary"
            onClick={() => handleClickButton('falha-microfone')}
          >
            Estou com problema no microfone
          </Button>
        </li>
      </ul>
    </div>
  );
}
