import React from 'react';
import useBasePageStyles from '../useBasePageStyles';
import useHelpContext from '../../../hooks/useHelpContext/useHelpContext';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';

import popupPermissoesImageAndroid from '../../../images/help/android/popup-permissoes.svg';
import iconeCadeadoImageAndroid from '../../../images/help/android/icone-cadeado.svg';
import iconeCameraImageAndroid from '../../../images/help/android/icone-camera.svg';
import modalPermissoesImageAndroid from '../../../images/help/android/modal-permissoes.svg';

import popupPermissoesImageWindows from '../../../images/help/windows/falha-microfone/modal-permissoes.png';
import modalPermissaoNegadaImageWindows from '../../../images/help/windows/falha-microfone/modal-permissao-negada.png';
import modalSolicitacaoPermissaoImageWindows from '../../../images/help/windows/falha-microfone/modal-solicitacao-permissao.png';

import { isMobile, BrowserView, MobileView } from 'react-device-detect';

export default function FalhaMicrofone() {
  const baseClasses = useBasePageStyles();
  const context = useHelpContext();

  const handleButtonClick = () => {
    context.setState({
      ...context.state,
      historySteps: [...context.state.historySteps, 'whatsapp'],
    });
  };

  return (
    <>
      <BrowserView>
        <div className={baseClasses.container}>
          <h1 className={baseClasses.title}>Estou com problema no microfone</h1>
          <p className={baseClasses.subtitle}>Se seu microfone não estiver funcionando, tente usar estas etapas:</p>
          <ol>
            <li>
              <span>No navegador, quando solicitado, selecione “Permitir”, como mostra abaixo:</span>
              <img src={modalSolicitacaoPermissaoImageWindows} />

              <p>
                Se o sistema não pedir acesso ou você já tiver bloqueado essa função, poderá mudar as configurações como
                mostra a seguir:
              </p>
              <ul>
                <li>
                  <span>No canto superior direito, selecione a câmera bloqueada:</span>
                  <img src={modalPermissaoNegadaImageWindows} />
                </li>
                <li>
                  Clique em “Sempre permitir que https://player.telemedicina.clude.com.br acesse sua câmera e seu
                  microfone”.
                </li>
                <li>
                  <span>
                    Clique em “Concluído”. Se não funcionar inicialmente, saia e entre na videochamada novamente.
                  </span>
                  <img src={popupPermissoesImageWindows} />
                </li>
              </ul>
            </li>
            <li>
              Se você estiver usando um fone de ouvido, verifique se o botão mudo no cabo está desativado. Verifique
              também se o som está ativado no site.
            </li>
            <li>Se não resolver, você pode tentar reiniciar o computador.</li>
          </ol>
          {/*<div className={baseClasses.footer}>*/}
          {/*  */}{/*<Button variant="contained" color="primary" className={baseClasses.button} onClick={handleButtonClick}>*/}
          {/*  */}{/*  Ainda tenho dúvidas ou problemas*/}
          {/*  */}{/*</Button>*/}
          {/*</div>*/}
        </div>
      </BrowserView>
      <MobileView>
        <div className={baseClasses.container}>
          <h1 className={baseClasses.title}>Estou com problema no microfone</h1>
          <p className={baseClasses.subtitle}>Se seu microfone não estiver funcionando, tente usar estas etapas:</p>
          <ol>
            <li>
              <span>No navegador, quando solicitado, selecione “Permitir”, como mostra abaixo:</span>
              <img src={popupPermissoesImageAndroid} />
              <p>
                Se o sistema não pedir acesso ou você já tiver bloqueado essa função, poderá mudar as configurações como
                mostra a seguir:
              </p>
              <ul>
                <li>
                  <span>No canto superior esquerdo, selecione a câmera bloqueada ou o ícone de cadeado.</span>
                  <img src={iconeCameraImageAndroid} />
                  <img src={iconeCadeadoImageAndroid} />
                </li>
                <li>
                  <span>Selecione “Permissões” e habilite o acesso à câmera e ao microfone:</span>
                  <img src={modalPermissoesImageAndroid} />
                </li>
              </ul>
            </li>
            <li>
              Se você estiver usando um fone de ouvido, verifique se o botão mudo no cabo está desativado. Verifique
              também se o som está ativado no site.
            </li>
            <li>Se não resolver, você pode tentar reiniciar o navegador ou o dispositivo.</li>
          </ol>
          {/*<div className={baseClasses.footer}>*/}
          {/*  */}{/*<Button variant="contained" color="primary" className={baseClasses.button} onClick={handleButtonClick}>*/}
          {/*  */}{/*  Ainda tenho dúvidas ou problemas*/}
          {/*  */}{/*</Button>*/}
          {/*</div>*/}
        </div>
      </MobileView>
    </>
  );
}
