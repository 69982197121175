import { PreflightTestReport } from 'twilio-video';
import { MediaConnectionBitrateTest } from '@twilio/rtc-diagnostics';

export enum QualityScore {
  Poor,
  Suboptimal,
  Good,
  Excellent,
  Unknown,
}

export type SingleQualityReport = {
  average: string | undefined;
  max: string | undefined;
  qualityScore: QualityScore;
};

export type QualityReport = {
  latency: SingleQualityReport;
  jitter: SingleQualityReport;
  packetLoss: SingleQualityReport;
  bitrate: SingleQualityReport;
  totalQualityScore: QualityScore;
};

export const qualityThresholds = {
  latency: {
    [QualityScore.Good]: 100,
    [QualityScore.Suboptimal]: 250,
    [QualityScore.Poor]: 400,
  },
  jitter: {
    [QualityScore.Good]: 5,
    [QualityScore.Suboptimal]: 10,
    [QualityScore.Poor]: 30,
  },
  packetLoss: {
    [QualityScore.Good]: 1,
    [QualityScore.Suboptimal]: 3,
    [QualityScore.Poor]: 8,
  },
  bitrate: {
    [QualityScore.Good]: 1000,
    [QualityScore.Suboptimal]: 500,
    [QualityScore.Poor]: 150,
  },
};

export function getSingleQualityScore(
  stat: number | undefined,
  goodThreshold: number,
  suboptimalThreshold: number,
  poorThreshold: number,
  descending: boolean = false
) {
  if (typeof stat === 'undefined') {
    // We ignore values that are missing
    return QualityScore.Unknown;
  }

  if (descending) {
    if (stat > goodThreshold) return QualityScore.Excellent;
    if (stat > suboptimalThreshold) return QualityScore.Good;
    if (stat > poorThreshold) return QualityScore.Suboptimal;
    return QualityScore.Poor;
  }

  if (stat >= poorThreshold) return QualityScore.Poor;
  if (stat >= suboptimalThreshold) return QualityScore.Suboptimal;
  if (stat >= goodThreshold) return QualityScore.Good;
  return QualityScore.Excellent;
}

export const formatNumber = (val: number | undefined) => {
  return val?.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });
};

export function getQualityReport(
  preflightTestReport: PreflightTestReport | null,
  bitrateTestReport: MediaConnectionBitrateTest.Report | null
): QualityReport {
  const maxBitrate = bitrateTestReport?.values ? Math.max(...bitrateTestReport.values) : undefined;

  const latency = {
    average: formatNumber(preflightTestReport?.stats!.rtt!.average),
    max: formatNumber(preflightTestReport?.stats!.rtt!.max),
    qualityScore: getSingleQualityScore(
      preflightTestReport?.stats!.rtt!.average,
      qualityThresholds.latency[QualityScore.Good],
      qualityThresholds.latency[QualityScore.Suboptimal],
      qualityThresholds.latency[QualityScore.Poor]
    ),
  } as SingleQualityReport;

  const jitter = {
    average: formatNumber(preflightTestReport?.stats!.jitter!.average),
    max: formatNumber(preflightTestReport?.stats!.jitter!.max),
    qualityScore: getSingleQualityScore(
      preflightTestReport?.stats!.jitter!.average,
      qualityThresholds.jitter[QualityScore.Good],
      qualityThresholds.jitter[QualityScore.Suboptimal],
      qualityThresholds.jitter[QualityScore.Poor]
    ),
  } as SingleQualityReport;

  const packetLoss = {
    average: formatNumber(preflightTestReport?.stats!.packetLoss!.average),
    max: formatNumber(preflightTestReport?.stats!.packetLoss!.max),
    qualityScore: getSingleQualityScore(
      preflightTestReport?.stats!.packetLoss!.average,
      qualityThresholds.packetLoss[QualityScore.Good],
      qualityThresholds.packetLoss[QualityScore.Suboptimal],
      qualityThresholds.packetLoss[QualityScore.Poor]
    ),
  } as SingleQualityReport;

  const bitrate = {
    average: formatNumber(bitrateTestReport?.averageBitrate!),
    max: formatNumber(maxBitrate),
    qualityScore: getSingleQualityScore(
      bitrateTestReport?.averageBitrate!,
      qualityThresholds.bitrate[QualityScore.Good],
      qualityThresholds.bitrate[QualityScore.Suboptimal],
      qualityThresholds.bitrate[QualityScore.Poor],
      true
    ),
  } as SingleQualityReport;

  const totalQualityScore = Math.min(
    latency.qualityScore,
    jitter.qualityScore,
    packetLoss.qualityScore,
    bitrate.qualityScore
  ) as QualityScore;

  return {
    latency,
    jitter,
    packetLoss,
    bitrate,
    totalQualityScore,
  } as QualityReport;
}
