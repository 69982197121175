// @ts-ignore
const AudioContext = window.AudioContext || window.webkitAudioContext;
let audioContext: AudioContext;

export function initializeAnalyser(stream: MediaStream) {
  audioContext = audioContext || new AudioContext();
  const audioSource = audioContext.createMediaStreamSource(stream);

  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.2;
  analyser.fftSize = 256;

  audioSource.connect(analyser);
  return analyser;
}

export function initializeAnalyserFromAudioElement(audio: HTMLMediaElement) {
  audioContext = audioContext || new AudioContext();
  const audioSource = audioContext.createMediaElementSource(audio);
  const analyser = audioContext.createAnalyser();
  analyser.smoothingTimeConstant = 0.2;
  analyser.fftSize = 256;
  audioSource.connect(analyser);
  analyser.connect(audioContext.destination);
  return analyser;
}

export function getVolumeFromAnalyser(analyser: AnalyserNode) {
  const sampleArray = new Uint8Array(analyser.frequencyBinCount);
  analyser.getByteFrequencyData(sampleArray);
  let values = 0;

  const length = sampleArray.length;
  for (let i = 0; i < length; i++) {
    values += sampleArray[i];
  }

  const volume = Math.min(14, Math.max(0, Math.log10(values / length / 3) * 7));
  return volume;
}
