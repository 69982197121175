import React from 'react';
import { Modal, makeStyles, Theme, createStyles, Slide } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import MainPage from './pages/MainPage';
import FalhaAudio from './pages/FalhaAudio';
import FalhaVideo from './pages/FalhaVideo';
import FalhaMicrofone from './pages/FalhaMicrofone';

import useHelpContext from '../../hooks/useHelpContext/useHelpContext';
import Whatsapp from './pages/Whatsapp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      width: '100vw',
      height: '100vh',
      backgroundColor: '#fff',
      position: 'absolute',
      display: 'grid',
      gridTemplateRows: 'auto 1fr',
      [theme.breakpoints.up('sm')]: {
        maxWidth: '482px',
        maxHeight: '95vh',
        borderRadius: '20px',
        minHeight: '300px',
        height: 'auto',
      },
    },
    header: {
      backgroundColor: '#fff',
      padding: '1em',
      width: 'inherit',
      maxWidth: 'inherit',
      display: 'flex',
      flexDirection: 'row',
      '& .MuiSvgIcon-root': {
        '&:hover': {
          cursor: 'pointer',
        },
        fontSize: '2em',
      },
      [theme.breakpoints.up('sm')]: {
        borderRadius: '20px 20px 0 0',
      },
    },
    closeButton: {
      marginLeft: 'auto',
    },
    content: {
      padding: '0 1em 1em 1em',
      overflowY: 'auto',
    },
  })
);

export default function HelpModal() {
  const context = useHelpContext();
  const classes = useStyles();
  const canGoBackwards = context.state.historySteps.length >= 2;
  const handleClose = () => {
    context.setModalState(false);
  };
  const handleBack = () => {
    if (!canGoBackwards) return;
    context.setState({
      ...context.state,
      historySteps: [...context.state.historySteps.slice(0, context.state.historySteps.length - 1)],
    });
  };
  const currentStep = context.state.historySteps[context.state.historySteps.length - 1];
  let stepElement;
  switch (currentStep) {
    case 'main':
      stepElement = <MainPage />;
      break;
    case 'falha-audio':
      stepElement = <FalhaAudio />;
      break;
    case 'falha-microfone':
      stepElement = <FalhaMicrofone />;
      break;
    case 'falha-video':
      stepElement = <FalhaVideo />;
      break;
    case 'whatsapp':
      stepElement = <Whatsapp />;
      break;
  }

  return (
    <Modal open={context.modalOpen} onClose={handleClose} className={classes.modal}>
      <Slide direction="up" in={context.modalOpen} timeout={500}>
        <div className={classes.container}>
          <div className={classes.header}>
            {canGoBackwards && <ArrowBackIcon onClick={handleBack} />}
            <CloseIcon className={classes.closeButton} onClick={handleClose} />
          </div>
          <div className={classes.content}>{stepElement}</div>
        </div>
      </Slide>
    </Modal>
  );
}
