import React from 'react';
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core';
import useBasePageStyles from '../useBasePageStyles';
import useHelpContext from '../../../hooks/useHelpContext/useHelpContext';
import { BrowserView, MobileView } from 'react-device-detect';

export default function FalhaVideo() {
  const baseClasses = useBasePageStyles();
  const context = useHelpContext();
  const handleButtonClick = () => {
    context.setState({
      ...context.state,
      historySteps: [...context.state.historySteps, 'whatsapp'],
    });
  };

  return (
    <>
      <BrowserView>
        <div className={baseClasses.container}>
          <h1 className={baseClasses.title}>Não estou vendo o profissional</h1>
          <p className={baseClasses.subtitle}>Se não está vendo o profissional, tente usar estas etapas:</p>
          <ol>
            <li>
              Pode ser algum problema de conexão. Certifique-se de usar a internet pelo cabo de rede ou WiFi sempre que
              possível;
            </li>
            <li>
              Fique mais perto do roteador ou mova ele para um local mais aberto com menos obstruções possíveis (portas,
              paredes, etc);
            </li>
            <li>Saia da videochamada e entre novamente;</li>
            <li>Se não resolver, você pode tentar reiniciar o computador;</li>
          </ol>
          {/*<div className={baseClasses.footer}>*/}
          {/*  */}{/*<Button variant="contained" color="primary" className={baseClasses.button} onClick={handleButtonClick}>*/}
          {/*  */}{/*  Ainda tenho dúvidas ou problemas*/}
          {/*  */}{/*</Button>*/}
          {/*</div>*/}
        </div>
      </BrowserView>
      <MobileView>
        <div className={baseClasses.container}>
          <h1 className={baseClasses.title}>Não estou vendo o profissional</h1>
          <p className={baseClasses.subtitle}>Se não está vendo o profissional, tente usar estas etapas:</p>
          <ol>
            <li>Pode ser algum problema de conexão. Certifique-se de usar o WiFi no celular sempre que possível.</li>
            <li>
              Fique mais perto do roteador ou mova ele para um local mais aberto com menos obstruções possíveis (portas,
              paredes, etc).
            </li>
            <li>Saia da videochamada e entre novamente.</li>
            <li>Se não resolver, você pode tentar reiniciar o dispositivo.</li>
          </ol>
          {/*<div className={baseClasses.footer}>*/}
          {/*  */}{/*<Button variant="contained" color="primary" className={baseClasses.button} onClick={handleButtonClick}>*/}
          {/*  */}{/*  Ainda tenho dúvidas ou problemas*/}
          {/*  */}{/*</Button>*/}
          {/*</div>*/}
        </div>
      </MobileView>
    </>
  );
}
