import React from 'react';

export const DiagnosticsSuccessStatus = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" fill="white" fillOpacity="0.01" />
    <circle cx="10" cy="10" r="6" fill="#14B053" />
  </svg>
);

export const DiagnosticsErrorStatus = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" fill="white" fillOpacity="0.01" />
    <circle cx="10" cy="10" r="6" fill="#D61F1F" />
  </svg>
);

export const DiagnosticsWarningStatus = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" fill="white" fillOpacity="0.01" />
    <circle cx="10" cy="10" r="6" fill="#F5A623" />
  </svg>
);

export const DiagnosticsUnknownStatus = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" fill="white" fillOpacity="0.01" />
    <circle cx="10" cy="10" r="6" fill="#d3d3da" />
  </svg>
);
