import React from 'react';
import { Button } from '@material-ui/core';
import useBasePageStyles from '../useBasePageStyles';
import useHelpContext from '../../../hooks/useHelpContext/useHelpContext';
import { BrowserView, MobileView } from 'react-device-detect';

import configuracaoAvancadaImageWindows from '../../../images/help/windows/falha-audio/configuracao-avancada.png';
import listaDispositivosImageWindows from '../../../images/help/windows/falha-audio/lista-dispositivos.png';
import modalBotaoDireitoImageWindows from '../../../images/help/windows/falha-audio/modal-botao-direito.png';
import opcoesDireitaConfiguracoesImageWindows from '../../../images/help/windows/falha-audio/opcoes-direita-configuracoes.png';

export default function FalhaAudio() {
  const baseClasses = useBasePageStyles();
  const context = useHelpContext();

  const handleButtonClick = () => {
    context.setState({
      ...context.state,
      historySteps: [...context.state.historySteps, 'whatsapp'],
    });
  };

  return (
    <>
      <BrowserView>
        <div className={baseClasses.container}>
          <h1 className={baseClasses.title}>Não estou ouvindo o profissional</h1>
          <p className={baseClasses.subtitle}>Se seu fone não estiver funcionando, tente usar estas etapas:</p>
          <ol>
            <li>
              Verifique se o Windows selecionou o fone de ouvido como dispositivo de saída de áudio padrão, depois de
              conectar o fone de ouvido no PC.
              <ul>
                <li>Clique com o botão direito do mouse no ícone de som na barra de tarefas.</li>
                <li>
                  <span>Selecione “Abrir configurações de som”.</span>
                  <img src={modalBotaoDireitoImageWindows} />
                </li>
                <li>
                  <span>Escolha “Painel de controle de som” à direita.</span>
                  <img src={opcoesDireitaConfiguracoesImageWindows} />
                </li>
                <li>
                  <span>Selecione os fones de ouvido e “Definir padrão”</span>
                  <img src={listaDispositivosImageWindows} />
                </li>
                <li>Clique em “Propriedades”. (Você pode renomear esta saída de som para facilitar a troca.)</li>
                <li>
                  <span>Selecione a guia “Avançado” e clique no botão “Testar”.</span>
                  <img src={configuracaoAvancadaImageWindows} />
                  <span>
                    Se você não ouvir o som saindo pelos fones de ouvido, verifique se o dispositivo correto foi
                    selecionado pelo Windows para saída de som. Se você ouviu o som pelos fones de ouvido, está tudo
                    certo.
                  </span>
                </li>
              </ul>
            </li>
            <li>Saia da videochamada e entre novamente.</li>
            <li>Se não resolver, você pode tentar reiniciar o dispositivo.</li>
          </ol>
          {/*<div className={baseClasses.footer}>*/}
          {/*  <Button variant="contained" color="primary" className={baseClasses.button} onClick={handleButtonClick}>*/}
          {/*    Ainda tenho dúvidas ou problemas*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </div>
      </BrowserView>
      <MobileView>
        <div className={baseClasses.container}>
          <h1 className={baseClasses.title}>Não estou ouvindo o profissional</h1>
          <p className={baseClasses.subtitle}>Se seu fone não estiver funcionando, tente usar estas etapas:</p>
          <ol>
            <li>
              Vá nas configurações de áudio do celular, entrando em: “Configurar” {'>'} “Som” e verificar o nível de
              volume e outras opções que podem estar interferindo o seu som.
            </li>
            <li>Se não resolver, você pode tentar reiniciar o dispositivo.</li>
          </ol>
          {/*<div className={baseClasses.footer}>*/}
          {/*  <Button variant="contained" color="primary" className={baseClasses.button} onClick={handleButtonClick}>*/}
          {/*    Ainda tenho dúvidas ou problemas*/}
          {/*  </Button>*/}
          {/*</div>*/}
        </div>
      </MobileView>
    </>
  );
}
