import React, { useState } from 'react';
import Snackbar from '../../Snackbar/Snackbar';
import useDevices from '../../../hooks/useDevices/useDevices';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export function getSnackbarContent(hasAudio: boolean, hasVideo: boolean, error?: Error) {
  let headline = '';
  let message = '';

  switch (true) {
    // These custom errors are thrown by the useLocalTracks hook. They are thrown when the user explicitly denies
    // permission to only their camera, or only their microphone.
    case error?.message === 'CameraPermissionsDenied':
      headline = 'Erro ao adquirir mídia:';
      message =
        'O usuário bloqueou a permissão para usar a câmera. Por favor, permita a utilização pelo navegador.';
        //'The user has denied permission to use video. Please grant permission to the browser to access the camera.';
      break;
    case error?.message === 'MicrophonePermissionsDenied':
      headline = 'Erro ao adquirir mídia:';
      message =
        'O usuário bloqueou a permissão para usar o microfone. Por favor, permita a utilização pelo navegador.';
        //'The user has denied permission to use audio. Please grant permission to the browser to access the microphone.';
      break;

    // This error is emitted when the user or the user's system has denied permission to use the media devices
    case error?.name === 'NotAllowedError':
      headline = 'Erro ao adquirir mídia:';

      if (error!.message === 'Permission denied by system') {
        // Chrome only
        message =
          'O sistema operacional bloqueou o acesso à câmera ou ao microfone. Verifique as configurações do sistema operacional.';
          //'The operating system has blocked the browser from accessing the microphone or camera. Please check your operating system settings.';
      } else {
        message =
          'O usuário bloqueou o acesso à câmera e ao microfone. Por favor, permita a utilização pelo navegador.';
          //'The user has denied permission to use audio and video. Please grant permission to the browser to access the microphone and camera.';
      }

      break;

    // This error is emitted when input devices are not connected or disabled in the OS settings
    case error?.name === 'NotFoundError':
      headline = 'Microfone ou câmera não detectados:';
      message =
        'O navegador não conseguiu acessar o microfone ou a câmera. Verifique se todos os dispositivos estão conectados.';
        //'The browser cannot access the microphone or camera. Please make sure all input devices are connected and enabled.';
      break;

    // Other getUserMedia errors are less likely to happen in this app. Here we will display
    // the system's error message directly to the user.
    case Boolean(error):
      headline = 'Erro ao adquirir mídia:';
      message = `${error!.name} ${error!.message}`;
      break;

    case !hasAudio && !hasVideo:
      headline = 'Microfone ou câmera não detectados:';
      message = 'Outros participantes na sala não conseguirão ver e ouvir você.';//'Other participants in the room will be unable to see and hear you.';
      break;

    case !hasVideo:
      headline = 'Câmera não detectada:';
      message = 'Outros participantes na sala não conseguirão ver você.'; //'Other participants in the room will be unable to see you.';
      break;

    case !hasAudio:
      headline = 'Microfone não detectado:';
      message = 'Outros participantes na sala não conseguirão ouvir você' //'Other participants in the room will be unable to hear you.';
  }

  return {
    headline,
    message,
  };
}

export default function MediaErrorSnackbar({ error }: { error?: Error }) {
  const { hasAudioInputDevices, hasVideoInputDevices } = useDevices();

  const { isAcquiringLocalTracks } = useVideoContext();

  const [isSnackbarDismissed, setIsSnackbarDismissed] = useState(false);

  const isSnackbarOpen =
    !isSnackbarDismissed &&
    !isAcquiringLocalTracks &&
    (Boolean(error) || !hasAudioInputDevices || !hasVideoInputDevices);

  const { headline, message } = getSnackbarContent(hasAudioInputDevices, hasVideoInputDevices, error);

  return (
    <Snackbar
      open={isSnackbarOpen}
      handleClose={() => setIsSnackbarDismissed(true)}
      headline={headline}
      message={message}
      variant="warning"
    />
  );
}
