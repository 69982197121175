import React, { useEffect, useRef, useState } from 'react';
import { interval } from 'd3-timer';
import ProgressBar from '../ProgressBar/ProgressBar';
import { getVolumeFromAnalyser, initializeAnalyserFromAudioElement } from './analyser';

function AudioLevelIndicator({ audioSource }: { audioSource?: HTMLAudioElement }) {
  const [analyserConnector, setAnalyserConnector] = useState<AnalyserNode>();
  const [volumeLevel, setVolumeLevel] = useState(0);

  useEffect(() => {
    if (audioSource) {
      setAnalyserConnector(initializeAnalyserFromAudioElement(audioSource));
    }
  }, [audioSource]);

  useEffect(() => {
    if (audioSource && analyserConnector) {
      const timer = interval(() => {
        setVolumeLevel(getVolumeFromAnalyser(analyserConnector));
      }, 100);

      return () => {
        timer.stop();
      };
    }
  }, [audioSource, analyserConnector]);

  return <ProgressBar duration={0.1} position={volumeLevel * 10} />;
}

export default React.memo(AudioLevelIndicator);
