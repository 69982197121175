import {
  Grid,
  Typography,
  Box,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
  createStyles,
  Theme,
  Tooltip,
} from '@material-ui/core';
import { MediaConnectionBitrateTest } from '@twilio/rtc-diagnostics';
import React, { useEffect, useState } from 'react';
import { PreflightTestReport } from 'twilio-video';
import {
  DiagnosticsErrorStatus,
  DiagnosticsSuccessStatus,
  DiagnosticsUnknownStatus,
  DiagnosticsWarningStatus,
} from '../../icons/StatusDiagnosticIcons';
import { getQualityReport, QualityReport, QualityScore } from '../../state/diagnostics/QualityScore/getQualityScore';
import { tooltipThresholds, toolTipContent } from './tooltipContent';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export type NetworkDiagnosticsReportType = {
  bitrateTestReport: MediaConnectionBitrateTest.Report | undefined;
  preflightTestReport: PreflightTestReport | undefined;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      '& div': {
        display: 'flex',
        alignItems: 'center',
      },
      '& svg': {
        marginRight: '0.3em',
      },
    },
    statusIconContainer: {
      width: '20px',
      height: '20px',
    },
    tooltipThreshold: {
      verticalAlign: 'middle',
      marginLeft: '0.3em',
    },
  })
);

export default function NetworkDiagnosticsReport({
  bitrateTestReport,
  preflightTestReport,
}: NetworkDiagnosticsReportType) {
  const classes = useStyles();
  const [qualityReport, setQualityReport] = useState<QualityReport>();
  const statusIcon = {
    [QualityScore.Excellent]: <DiagnosticsSuccessStatus />,
    [QualityScore.Good]: <DiagnosticsSuccessStatus />,
    [QualityScore.Suboptimal]: <DiagnosticsWarningStatus />,
    [QualityScore.Poor]: <DiagnosticsErrorStatus />,
    [QualityScore.Unknown]: <DiagnosticsUnknownStatus />,
  };
  useEffect(() => {
    setQualityReport(getQualityReport(preflightTestReport || null, bitrateTestReport || null));
  }, [bitrateTestReport, preflightTestReport]);
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="body1">
              <strong>Categoria</strong>
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="body1">
              <strong>Média/Máxima</strong>
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell>
            <div className={classes.iconContainer}>
              <Tooltip
                title={toolTipContent.latency}
                interactive
                leaveDelay={250}
                leaveTouchDelay={15000}
                enterTouchDelay={0}
              >
                <InfoOutlinedIcon color="secondary" fontSize="small" />
              </Tooltip>
              <div className={classes.statusIconContainer}>
                {statusIcon[qualityReport?.latency.qualityScore ?? QualityScore.Excellent]}
              </div>
              <Typography variant="body1">Latência (ms)</Typography>
            </div>
          </TableCell>
          <TableCell>
            <Typography variant="body1">
              {`${qualityReport?.latency.average ?? '-'} / ${qualityReport?.latency.max ?? '-'}`}
              <Tooltip
                title={tooltipThresholds.latency}
                interactive
                leaveDelay={250}
                leaveTouchDelay={15000}
                enterTouchDelay={0}
                className={classes.tooltipThreshold}
              >
                <InfoOutlinedIcon color="secondary" fontSize="small" />
              </Tooltip>
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <div className={classes.iconContainer}>
              <Tooltip
                title={toolTipContent.jitter}
                interactive
                leaveDelay={250}
                leaveTouchDelay={15000}
                enterTouchDelay={0}
              >
                <InfoOutlinedIcon color="secondary" fontSize="small" />
              </Tooltip>
              <div className={classes.statusIconContainer}>
                {statusIcon[qualityReport?.jitter.qualityScore ?? QualityScore.Excellent]}
              </div>
              <Typography variant="body1">Jitter (s)</Typography>
            </div>
          </TableCell>
          <TableCell>
            <Typography variant="body1">
              {`${qualityReport?.jitter.average ?? '-'} / ${qualityReport?.jitter.max ?? '-'}`}
              <Tooltip
                title={tooltipThresholds.jitter}
                interactive
                leaveDelay={250}
                leaveTouchDelay={15000}
                enterTouchDelay={0}
                className={classes.tooltipThreshold}
              >
                <InfoOutlinedIcon color="secondary" fontSize="small" />
              </Tooltip>
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <div className={classes.iconContainer}>
              <Tooltip
                title={toolTipContent.packetLoss}
                interactive
                leaveDelay={250}
                leaveTouchDelay={15000}
                enterTouchDelay={0}
              >
                <InfoOutlinedIcon color="secondary" fontSize="small" />
              </Tooltip>
              <div className={classes.statusIconContainer}>
                {statusIcon[qualityReport?.packetLoss.qualityScore ?? QualityScore.Excellent]}
              </div>
              <Typography variant="body1">Perda de pacote</Typography>
            </div>
          </TableCell>
          <TableCell>
            <Typography variant="body1">
              {`${qualityReport?.packetLoss.average ?? '-'} / ${qualityReport?.packetLoss.max ?? '-'}`}
              <Tooltip
                title={tooltipThresholds.packetLoss}
                interactive
                leaveDelay={250}
                leaveTouchDelay={15000}
                enterTouchDelay={0}
                className={classes.tooltipThreshold}
              >
                <InfoOutlinedIcon color="secondary" fontSize="small" />
              </Tooltip>
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <div className={classes.iconContainer}>
              <Tooltip
                title={toolTipContent.bitrate}
                interactive
                leaveDelay={250}
                leaveTouchDelay={15000}
                enterTouchDelay={0}
              >
                <InfoOutlinedIcon color="secondary" fontSize="small" />
              </Tooltip>
              <div className={classes.statusIconContainer}>
                {statusIcon[qualityReport?.bitrate.qualityScore ?? QualityScore.Excellent]}
              </div>
              <Typography variant="body1">Bitrate (kbps)</Typography>
            </div>
          </TableCell>
          <TableCell>
            <Typography variant="body1">
              {`${qualityReport?.bitrate.average ?? '-'} / ${qualityReport?.bitrate.max ?? '-'}`}
              <Tooltip
                title={tooltipThresholds.bitrate}
                interactive
                leaveDelay={250}
                leaveTouchDelay={15000}
                enterTouchDelay={0}
                className={classes.tooltipThreshold}
              >
                <InfoOutlinedIcon color="secondary" fontSize="small" />
              </Tooltip>
            </Typography>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
