import React from 'react';
import { QualityScore, qualityThresholds } from '../../state/diagnostics/QualityScore/getQualityScore';
export const toolTipContent = {
  latency: `A latência é o tempo que um pacote de dados leva para ir do remetente ao destinatário e vice-versa. Latência alta pode causar atraso, congelamento ou lentidão do vídeo. Isso geralmente ocorre por causa de rede lenta ou sobrecarregada.`,
  jitter: `Jitter é a flutuação da latência ao longo do tempo. Devido ao congestionamento da rede ou problemas de performance de hardware, os dados podem demorar mais para viajar. O jitter alto normalmente resulta em congelamentos temporários de vídeo, vídeo lento e áudio instável.`,
  packetLoss: `A perda de pacotes é causada quando os dados não chegam ao seu destino. A alta perda de pacotes resulta em congelamento de vídeo, vídeo lento e áudio instável. A perda de pacotes geralmente é causada por roteadores sobrecarregados ou alta carga de CPU na máquina.`,
  bitrate: `A taxa de bits é a maneira como medimos a velocidade das transferências de upload e download. Se um remetente carregar dados muito rapidamente e exceder a velocidade de transferência, a rede ficará sobrecarregada e poderá causar problemas para todos. Os sintomas disso podem ser vídeo congelado, vídeo com escala reduzida, quedas na taxa de quadros, áudio instável e conexões potencialmente interrompidas.`,
};

export const tooltipThresholds = {
  latency: (
    <div>
      Bom: {qualityThresholds.latency[QualityScore.Good]} ms <br />
      Médio: {qualityThresholds.latency[QualityScore.Suboptimal]} ms <br />
      Ruim: {qualityThresholds.latency[QualityScore.Poor]} ms
    </div>
  ),
  jitter: (
    <div>
      Bom: {qualityThresholds.jitter[QualityScore.Good]} s <br />
      Médio: {qualityThresholds.jitter[QualityScore.Suboptimal]} s <br />
      Ruim: {qualityThresholds.jitter[QualityScore.Poor]} s
    </div>
  ),
  packetLoss: (
    <div>
      Bom: {qualityThresholds.packetLoss[QualityScore.Good]} <br />
      Médio: {qualityThresholds.packetLoss[QualityScore.Suboptimal]} <br />
      Ruim: {qualityThresholds.packetLoss[QualityScore.Poor]}
    </div>
  ),
  bitrate: (
    <div>
      Bom: {qualityThresholds.bitrate[QualityScore.Good]} kbps <br />
      Médio: {qualityThresholds.bitrate[QualityScore.Suboptimal]} kbps <br />
      Ruim: {qualityThresholds.bitrate[QualityScore.Poor]} kbps
    </div>
  ),
};
