import React, { createContext, ReactNode, useState, useEffect } from 'react';

export type HelpStepTag = 'main' | 'falha-audio' | 'falha-video' | 'falha-microfone' | 'whatsapp';

export type HelpStateType = {
  historySteps: HelpStepTag[];
  modalTimeoutAnimation: number;
};

export type IHelpContext = {
  state: HelpStateType;
  setState: React.Dispatch<React.SetStateAction<HelpStateType>>;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
  modalOpen: boolean;
};

export type HelpProviderProps = {
  children: ReactNode;
};

const DEFAULT_VALUE = {
  state: {
    historySteps: ['main'] as HelpStepTag[],
    modalTimeoutAnimation: 500,
  },
  setState: () => {},
  setModalState: () => {},
  modalOpen: false,
} as IHelpContext;

export const HelpContext = createContext<IHelpContext>(DEFAULT_VALUE);

export function HelpProvider({ children }: HelpProviderProps) {
  const [state, setState] = useState<HelpStateType>(DEFAULT_VALUE.state);
  const [modalOpen, setModalState] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setState(DEFAULT_VALUE.state);
    }, state.modalTimeoutAnimation);
  }, [modalOpen]);

  return (
    <HelpContext.Provider
      value={{
        state,
        setState,

        setModalState: setModalState,
        modalOpen,
      }}
    >
      {children}
    </HelpContext.Provider>
  );
}
