import React, { useEffect, useState } from 'react';
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Button,
} from '@material-ui/core';
import { useAppState } from '../../../state';
import useDevices from '../../../hooks/useDevices/useDevices';
import testSound from '../../../sounds/test_sound.mp3';
import useAudio from '../../../hooks/useAudio/useAudio';

import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';

import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    audioLevelIndicatorContainer: {
      marginTop: '1em',
    },
    toggleAudioIconButton: {
      minWidth: '0',
      padding: '6px',
      width: '37px',
      height: '36px',
    },
    audioLevelIndicator: {
      flexGrow: 1,
    },
  })
);

export default function AudioOutputList() {
  const classes = useStyles();
  const { playing, toggle: togglePlaying, audioSource } = useAudio(testSound, true);
  const { audioOutputDevices } = useDevices();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId)?.label;

  return (
    <>
      <div className="inputSelect">
        {audioOutputDevices.length > 1 ? (
          <FormControl fullWidth>
            <Typography variant="subtitle2" gutterBottom>
              Saída de áudio
            </Typography>
            <Select onChange={e => setActiveSinkId(e.target.value as string)} value={activeSinkId} variant="outlined">
              {audioOutputDevices.map(device => (
                <MenuItem value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <>
            <Typography variant="subtitle2">Saída de Áudio</Typography>
            <Typography>{activeOutputLabel || 'Saída de áudio padrão'}</Typography>
          </>
        )}
      </div>
      <Grid container direction="row" alignItems="center" className={classes.audioLevelIndicatorContainer}>
        <Button className={classes.toggleAudioIconButton} onClick={togglePlaying}>
          {playing ? <PauseIcon htmlColor="#707578" /> : <PlayArrowOutlinedIcon htmlColor="#707578" />}
        </Button>
        <div className={classes.audioLevelIndicator}>
          <AudioLevelIndicator audioSource={audioSource} />
        </div>
      </Grid>
    </>
  );
}
