import React from 'react';
import { Card, CardContent, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import IconeWhatsApp from '../../../images/Whatsapp.svg';

import useBasePageStyles from '../useBasePageStyles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      backgroundColor: '#DDF5E4',
      borderRadius: '10px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    cardContent: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.typography.fontSize,
      fontWeight: 'bold',
      color: '#6C6B75',
    },
    cardImage: {
      width: '80px',
      height: '80px',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  })
);

export default function Whatsapp() {
  const baseClasses = useBasePageStyles();
  const classes = useStyles();

  const handleWhatsappClick = () => {
    window.open('https://api.whatsapp.com/send?phone=551142803624');
  };

  return (
    <div>
      <h1 className={baseClasses.title}>Ainda tenho dúvidas ou problemas</h1>
      <p className={baseClasses.subtitle}>
        Entre em contato com nosso time de atendimento pelo Whatsapp para podermos te ajudar
      </p>
      <Card elevation={0} className={classes.card} onClick={handleWhatsappClick}>
        <CardContent className={classes.cardContent}>
          <p>Fale com nosso time de atendimento</p>
          <img className={classes.cardImage} src={IconeWhatsApp} alt="Whatsapp image" />
        </CardContent>
      </Card>
    </div>
  );
}
