import { createStyles, makeStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    container: {
      '& ul': {
        listStyleType: 'disc',
      },
      '& ol,ul': {
        listStylePosition: 'inside',
        padding: 0,
        '& > li': {
          paddingBottom: theme.typography.fontSize,
          '&::marker': {
            fontWeight: 'bold',
          },
        },
        '& > ol,ul': {
          paddingLeft: theme.typography.fontSize,
          '& img': {
            [theme.breakpoints.up('sm')]: {
              display: 'block',
              marginLeft: 'auto',
              marginRight: 'auto',
            },
          },
        },
        '& img': {
          [theme.breakpoints.down('sm')]: {
            width: '100%',
          },
          [theme.breakpoints.up('sm')]: {
            width: 'auto',
            display: 'block',
            marginLeft: 'auto',
            marginRight: 'auto',
          },
        },
      },
    },
    title: {
      marginTop: 0,
      textAlign: 'center',
      fontSize: '1.5em',
    },
    subtitle: {
      textAlign: 'center',
      fontSize: '18px',
    },
    footer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      maxWidth: '335px',
      width: '100%',
      borderRadius: '30px',
      padding: theme.typography.fontSize,
      fontSize: theme.typography.fontSize,
      fontWeight: 700,
    },
  })
);
