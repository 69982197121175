import React from 'react';
import AudioTrackLevelIndicator from '../../AudioLevelIndicator/AudioTrackLevelIndicator';
import { LocalAudioTrack } from 'twilio-video';
import {
  FormControl,
  MenuItem,
  Typography,
  Select,
  Grid,
  makeStyles,
  createStyles,
  Theme,
  Button,
} from '@material-ui/core';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../constants';
import useDevices from '../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    audioLevelIndicatorContainer: {
      marginTop: '1em',
    },
    toggleAudioIconButton: {
      minWidth: '0',
      padding: '6px',
      width: '37px',
      height: '36px',
    },
    audioLevelIndicator: {
      flexGrow: 1,
    },
  })
);

export default function AudioInputList() {
  const classes = useStyles();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { audioInputDevices } = useDevices();
  const { localTracks } = useVideoContext();
  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  return (
    <div>
      <Typography variant="subtitle2" gutterBottom>
        Entrada de áudio
      </Typography>
      <Grid container direction="column">
        <div className="inputSelect">
          {audioInputDevices.length > 1 ? (
            <FormControl fullWidth>
              <Select
                onChange={e => replaceTrack(e.target.value as string)}
                value={localAudioInputDeviceId || ''}
                variant="outlined"
              >
                {audioInputDevices.map(device => (
                  <MenuItem value={device.deviceId} key={device.deviceId}>
                    {device.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography>{localAudioTrack?.mediaStreamTrack.label || 'Sem entrada disponível'}</Typography>
          )}
        </div>
      </Grid>
      <Grid container direction="row" alignItems="center" className={classes.audioLevelIndicatorContainer}>
        <Button className={classes.toggleAudioIconButton} onClick={toggleAudioEnabled}>
          {isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
        </Button>
        <div className={classes.audioLevelIndicator}>
          <AudioTrackLevelIndicator audioTrack={localAudioTrack} />
        </div>
      </Grid>
    </div>
  );
}
