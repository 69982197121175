import React, { useState } from 'react';
import { makeStyles, Typography, Grid, Button, Theme, Hidden } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import LocalVideoPreview from './LocalVideoPreview/LocalVideoPreview';
import SettingsMenu from './SettingsMenu/SettingsMenu';
import SignalCellularConnectedNoInternet2BarIcon from '@material-ui/icons/SignalCellularConnectedNoInternet2Bar';
import SettingsIcon from '@material-ui/icons/Settings';
import { Steps } from '../PreJoinScreens';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import NetworkDiagnostics from '../../NetworkDiagnostics/NetworkDiagnostics';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    marginBottom: '1em',
  },
  marginTop: {
    marginTop: '1em',
  },
  deviceButton: {
    width: '100%',
    border: '2px solid #aaa',
    margin: '1em 0',
  },
  localPreviewContainer: {
    padding: '0 5em',
    [theme.breakpoints.down('sm')]: {
      padding: '0 0.5em',
    },
  },
  joinButtons: {
    display: 'flex',
    margin: '0 0 1em 0',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
      width: '100%',
      '& button': {
        margin: '0 0 0.5em 0',
      },
    },
  },
  toggleAudioVideoBar: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '1.5em 0 1em',
  },
  toggleAudioVideoButton: {
    padding: '0.8em 0',
    margin: 0,
  },
}));

interface DeviceSelectionScreenProps {
  userIdentity: string;
  name: string;
  roomName: string;
  setStep: (step: Steps) => void;
}

export default function DeviceSelectionScreen({ name, userIdentity, roomName, setStep }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { getToken, isFetching } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, isAcquiringLocalTracks, isConnecting } = useVideoContext();
  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;
  const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
  const [networkSettingsDialogOpen, setNetworkSettingsDialogOpen] = useState(false);

  const handleJoin = () => {
    getToken(name, userIdentity, roomName)
      .then(res => {
        videoConnect(res.token);
        process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && chatConnect(res.token);
      })
      .catch(err => {
        return Promise.resolve();
      });
  };

  if (isFetching || isConnecting) {
    return (
      <Grid container justifyContent="center" alignItems="center" direction="column" style={{ height: '100%' }}>
        <div>
          <CircularProgress variant="indeterminate" />
        </div>
        <div>
          <Typography variant="body2" style={{ fontWeight: 'bold', fontSize: '16px' }}>
            Entrando...
          </Typography>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Typography variant="h5" className={classes.gutterBottom}>
        Entrar na sala{/*roomName*/}
      </Typography>

      <Grid container justifyContent="center">
        <Grid item md={12} sm={12} xs={12}>
          <div className={classes.localPreviewContainer}>
            <LocalVideoPreview identity={name} />
          </div>
          <div className={classes.toggleAudioVideoBar}>
            <ToggleAudioButton className={classes.toggleAudioVideoButton} disabled={disableButtons} />
            <ToggleVideoButton className={classes.toggleAudioVideoButton} disabled={disableButtons} />
            {/* <SettingsMenu mobileButtonClass={classes.mobileButton} /> */}
          </div>
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Grid container direction="column" justifyContent="space-between" style={{ height: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              data-cy-join-now
              onClick={handleJoin}
              disabled={disableButtons}
              className={classes.joinButtons}
            >
              Entrar
            </Button>
            <Button
              color="secondary"
              variant="contained"
              disabled={disableButtons}
              onClick={() => setSettingsDialogOpen(true)}
              startIcon={<SettingsIcon />}
              className={classes.joinButtons}
            >
              Configurações de áudio e vídeo
            </Button>
            <Button
              color="secondary"
              variant="contained"
              disabled={disableButtons}
              onClick={() => setNetworkSettingsDialogOpen(true)}
              startIcon={<SignalCellularConnectedNoInternet2BarIcon />}
              className={classes.joinButtons}
            >
              Teste de conexão
            </Button>

            <DeviceSelectionDialog open={settingsDialogOpen} onClose={() => setSettingsDialogOpen(false)} />
            <NetworkDiagnostics open={networkSettingsDialogOpen} onClose={() => setNetworkSettingsDialogOpen(false)} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
